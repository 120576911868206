import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import {PageType} from 'web/helpers/redux_client';
import MarketSidebar from 'web/components/market_sidebar';
import MarketBreadcrumbs from 'web/components/market_breadcrumbs';

import {StoreData} from './controller';
import Section from './components/section';
import CuratedForYouBanner from './components/info_banner';

const CuratedForYouPage: PageType<StoreData> = () => {
  const props = useSelector((state: StoreData) => {
    return {
      sortedCategories: state.sortedCategories,
      results: state.results,
      products: state.products,
      user: state.user,
    };
  });
  const sideBarGroups = [
    {
      displayName: 'Curated for You',
      id: 'for-you',
      menuItems: props.sortedCategories.map(({marketCategory}) => ({
        id: marketCategory.id,
        displayName: marketCategory.name,
      })),
    },
  ];

  const hasAnsweredSurvey = props.user?.survey.hasAnswered ?? false;

  return (
    <MarketLayout>
      <Helmet>
        <title>Curated for You | Good Eggs</title>
      </Helmet>

      <div className="content">
        <MarketBreadcrumbs
          category={{name: 'For You'}}
          subcategory={{name: 'Curated for You', url: '/for-you'}}
        />
        <div className="product-listings-page__sidebar">
          <MarketSidebar menuGroups={sideBarGroups} />
        </div>
        <div className={classNames('products-page', 'product-listings-view', 'with-sidebar')}>
          <CuratedForYouBanner hasAnsweredSurvey={hasAnsweredSurvey} />
          <div className="product-tiles">
            <h2 className="for-you__section-header" id="for-you" data-testid="for-you__title">
              Curated for You
            </h2>
            <h4 className="for-you__section-subtitle" id="for-you" data-testid="for-you__subtitle">
              An auxiliary text providing extra explanation on how the categories work in the FYP,
              or any other type of clarification needed about the value proposition.
            </h4>
            {props.sortedCategories.map(({marketCategory, productIds}) => {
              return (
                <Section
                  category={marketCategory}
                  productIds={productIds}
                  key={`${marketCategory.id}-${marketCategory.shortName}`}
                />
              );
            })}
          </div>
          <a className="js-mobile-to-top product-listings-view__back-to-top" href="#">
            Back to Top
          </a>
        </div>
      </div>
    </MarketLayout>
  );
};

CuratedForYouPage.reducer = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  return marketLayoutReducer(state, action);
};

CuratedForYouPage.pageName = 'Curated For You';

export default CuratedForYouPage;
